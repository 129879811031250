import * as React from "react";
import { useNavigate } from 'react-router-dom';
import { Card, CardContent } from '@mui/material';
import CustomTooltip from "../../tooltips/customTooltip/CustomTooltip";
import styles from './MainInfoProfileCard.module.css';
import EditIcon from '../../../assets/Edit.png';
import { IProfile } from "../../../utils/types";
import {useTranslate} from "react-admin";

type MainInfoProfileCardProps = {
  profile: IProfile,
  editable: boolean
}

const MainInfoProfileCard = (props: MainInfoProfileCardProps) => {
  const { profile, editable } = props;
  const navigate = useNavigate();
  const translate = useTranslate();
  const editProfile = () => {
    navigate(`/user/${profile.id}/edit/main`);
  }

  return (
    <Card>
      <CardContent>
        <div className={styles.root}>
          <h1 className={styles.header}>{translate('operations.params.USER.basicInfo')}</h1>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.givenName')}</span>
            <span className={styles.item_value}>{`${profile?.sn ? profile?.sn : ''} ${profile?.givenName}`}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.sAMAccountName')}</span>
            <span className={styles.item_value}>{profile.id}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.dateOfBirth')}</span>
            <span className={styles.item_value}>{(profile.description || '').split('.').map((item, i) => i === 1 ? item.slice(-2) : item).join('.')}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.initials')}</span>
            <span className={styles.item_value}>{profile.initials === translate('operations.params.USER.none') ? '' : profile.initials}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.resident')}</span>
            <span className={styles.item_value}>{profile.facsimileTelephoneNumber && Number(profile?.facsimileTelephoneNumber.split('/')[0]) ? translate('operations.params.USER.residentYes') : translate('operations.params.USER.residentNo')}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.postalCode')}</span>
            <span className={styles.item_value}>{profile.postalCode}</span>
          </div>
          <div className={styles.item}>
            <span className={styles.item_name}>{translate('operations.params.USER.residentialAddress')}</span>
            <span className={styles.item_value}>{profile.streetAddress}</span>
          </div>
          {
            editable ?
            <div className={styles.edit_icon} onClick={editProfile}>
              <CustomTooltip title={translate('ra.action.edit')} >
                <img src={EditIcon} alt="icon" />
              </CustomTooltip>
            </div>
            :
            null
          }
        </div>
      </CardContent>
    </Card>
  )
};

export default MainInfoProfileCard;